import React from "react";
import topbar from "../assets/Windroos-topbar.png";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";

function Topbar() {
  return (
    <div>
      <img src={topbar} alt="Windroos" className="img-header" />
      <Typography variant="h6">
        Wij zijn op zoek naar een schoolleider, lees{" "}
        <RouterLink to="/vacatures">Hier</RouterLink> meer.
      </Typography>
    </div>
  );
}

export default Topbar;
