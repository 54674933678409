import React from "react";
import Container from "@mui/material/Container";
import YoutubeEmbedder from "../components/YoutubeEmbedder";
import Typography from "@mui/material/Typography";

function Inhetnieuws() {
  return (
    <>
      <Container style={{ maxWidth: "720px", marginLeft: "10%" }}>
        <Typography variant="h5" sx={{ marginTop: 2 }}>
          De Windroos - In het nieuws
        </Typography>
        <br />
        <Typography variant="body1" sx={{ marginTop: 2, marginBottom: 2 }}>
          Regelmatig komt de Windroos in het nieuws. Niet alleen met lokale
          nieuwsberichten, maar ook bijvoorbeeld voor het programma Onderweg in
          de regio van RTL4, de Kleuteruniversiteit en meester Sander.
        </Typography>{" "}
        <br />
        <Typography variant="h6" sx={{ marginTop: 2, marginBottom: 2 }}>
          Onderweg in de regio
        </Typography>
        <YoutubeEmbedder embedId="Jr_EB8JBMMQ?list=PLEk0A8k1mG78GUtNGjTnblHsfjf4Ow6Ex" />
        <br />
        <Typography variant="h6" sx={{ marginTop: 2, marginBottom: 2 }}>
          Podcast over de Windroos - Een gemeenschap waar iedereen wordt gezien
          en uitgedaagd.
        </Typography>{" "}
        <Typography variant="body" sx={{ marginTop: 2, marginBottom: 2 }}>
          Jan en Anouk vertellen in een podcast van Nivoz over vorming,
          pedagogisch leiderschap en de schoolpraktijk van de Windroos.
        </Typography>
        <a
          href="https://nivoz.nl/nl/podcast-over-de-windroos-een-gemeenschap-waarin-iedereen-wordt-gezien-en-uitgedaagd"
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          Link naar artikelen en de podcast
        </a>
        <Typography variant="h6" sx={{ marginTop: 2, marginBottom: 2 }}>
          Kleuteruniversiteit
        </Typography>{" "}
        <Typography variant="body" sx={{ marginTop: 2, marginBottom: 2 }}>
          Meester Sander was samen met de kleuteruniversiteit op de Windroos.
          Klik op de link om het artikel te lezen.
        </Typography>
        <a
          href="https://wrassets.boss-tech.nl/beelden/KleuterUniversiteit excellente verhalen oktober 2022.pdf"
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          Artikel Kleuteruniversiteit
        </a>
        <img
          src="https://wrassets.boss-tech.nl/beelden/inhetnieuws_kleuteruni.png"
          alt="kleuteruniversiteit"
          style={{ width: "100%", borderRadius: "5%" }}
        />
        <Typography variant="h6" sx={{ marginTop: 2, marginBottom: 2 }}>
          Prikkels... - leerlingen aan het stuur
        </Typography>{" "}
        <Typography variant="body" sx={{ marginTop: 2, marginBottom: 2 }}>
          Wat hebben kinderen nodig om de wereld te begrijpen? En speel
          cultuureducatie daarin een rol? Een gepsrek over weerbaarheid en de
          eigen leefwereld van kinderen, met Windroos-leerkrachten Maaike en
          Loes!
        </Typography>
        <a
          href="https://wrassets.boss-tech.nl/beelden/Prikkels januari 2023 Leerlingen aan het stuur.pdf"
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          Artikel in prikkels...
        </a>
        <img
          src="https://wrassets.boss-tech.nl/beelden/PrikkelsCoverfoto.png"
          alt="kleuteruniversiteit"
          style={{ width: "100%", borderRadius: "5%" }}
        />
      </Container>
    </>
  );
}

export default Inhetnieuws;
