import React from "react";
import Typography from "@mui/material/Typography";

function Vacatures() {
  return (
    <div style={{ maxWidth: "720px", marginLeft: "10%", marginTop: "10px" }}>
      <Typography variant="h4">Vacatures</Typography>
      <Typography variant="h6" sx={{ marginBottom: 3 }}>
        Vacature schoolleider (0,6 - 0,8 wtf)
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: 3 }}>
        Een enthousiaste schoolleider die:
        <ul>
          <li>
            Affiniteit heeft met het onderwijsconcept Freinet en dit met
            enthousiasme uitdraagt;
          </li>
          <li>Betrokken is bij kinderen, teamleden en ouders;</li>
          <li>Ervaring heeft in het sturen op opbrengsten;</li>
          <li>Goed grenzen kan bewaken en focus kan aanbrengen;</li>
          <li>Talenten benut van de teamleden;</li>
          <li>
            De positieve, professionele cultuur stimuleert waar samenwerken de
            norm is;
          </li>
          <li>
            Communiceert op een open en betrokken wijze en zichtbaar is voor
            kinderen, ouders, collega’s en sociale partners;
          </li>
          <li>
            Besluitvaardig is, daadkracht heeft en in staat is om zowel
            financiële als bedrijfsmatige processen te leiden;
          </li>
          <li>Een actieve bijdrage kan leveren aan Het Sticht;</li>
          <li>
            De schoolleidersopleiding heeft afgerond, of bereid is deze te
            behalen.
          </li>
        </ul>
        Op de Windroos werkt een team dat elkaar helpt, ondersteunt, aanscherpt,
        feedback geeft, consulteert en zo het onderwijs doorontwikkelt. Wij
        nodigen kandidaten met weinig ervaring als schoolleider ook van harte
        uit om te solliciteren.
      </Typography>
      {/* <Typography variant="body1" sx={{ marginBottom: 16 }}>
        Op dit moment zijn er geen vacatures. Het kan natuurlijk zijn dat jij
        vindt dat we jou moeten kennen. Dus: wil je nu niet solliciteren, maar
        wel dat wij je kennen, voor wat de toekomst misschien ook brengt? Neem
        dan contact met ons op.
      </Typography> */}
      <a
        href="https://wrassets.boss-tech.nl/documents/Vacature schoolleider de Windroos.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        Volledige vacature
      </a>
      <br />
      <img
        src="https://wrassets.boss-tech.nl/beelden/zomerfeest.JPG"
        alt="Zomerfeest - deel van de vieringen die wij kennen binnen ons onderwijs"
        style={{ width: "100%", borderRadius: "5%" }}
        loading="lazy"
      />
    </div>
  );
}

export default Vacatures;
